<script>
export default {
  name: "changeType",
  props: ["user", "id"],
  data: () => ({
    dialog: false,
    showPassword: false,
    loading: false,
    isInvestor: false,
    valid: true,
    body: {
      newPassword: null,
      confirmPassword: null,
    },
  }),
  methods: {
    closeDown() {
      this.dialog = false;
      this.reset();
      this.resetValidation();
    },
    openDialog() {
      this.reset();
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$http
          .put(
            `${this.$route.meta.endPoint}/changePasswordByAdmin/${this.id}`,
            this.body
          )
          .then((res) => {
            // this.$emit(`refresh`);
            this.closeDown();
            this.$store.dispatch("toastification/setToast", {
              message: `${res.data.message}`,
              type: "success",
            });
          })
          .catch((err) => {
            this.$store.dispatch("toastification/setToast", {
              message: `${err.response.data.message}`,
              type: "error",
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reset() {
      this.body = {
        newPassword: null,
        confirmPassword: null,
      };
    },
  },
};
</script>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="60%"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon @click="openDialog()" v-bind="attrs" v-on="on">
          mdi-account-settings
        </v-icon>
      </template>
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="45" flat>
          <h5>
            تغير كلمة مرور ال
            {{ this.$route.meta.single }}
          </h5>
          <v-spacer />

          <v-btn @click="closeDown()" fab small icon color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form
          @submit.prevent="submit()"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <!-- password -->
                <v-col cols="12" md="12">
                  <h6 class="mb-1">
                    كلمة المرور
                    <span class="required">*</span>
                  </h6>
                  <v-text-field
                    v-model="body.newPassword"
                    placeholder="كلمة المرور"
                    :rules="[$global.state.passwordFormat()]"
                    required
                    filled
                    dense
                    outlined
                    :type="showPassword ? 'text' : 'password'"
                    :prepend-inner-icon="
                      showPassword ? 'mdi-eye' : 'mdi-eye-closed'
                    "
                    @click:prepend-inner="showPassword = !showPassword"
                    hide-details="auto"
                  />
                </v-col>
                <!--confirm password -->
                <v-col cols="12" md="12">
                  <h6 class="mb-1">
                    تأكيد كلمة المرور
                    <span class="required">*</span>
                  </h6>
                  <v-text-field
                    :prepend-inner-icon="
                      showPassword ? 'mdi-eye' : 'mdi-eye-closed'
                    "
                    @click:prepend-inner="showPassword = !showPassword"
                    v-model="body.confirmPassword"
                    placeholder="تأكيد كلمة المرور"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[
                      body.newPassword == body.confirmPassword ||
                        'كلماة المرور غير متشابه',
                    ]"
                    required
                    filled
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-divider class="ml- mt-8" />
              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn @click="closeDown()" text depressed class="mr-2 btn"
                  >إلغاء</v-btn
                >
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
</style>